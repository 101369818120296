<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.pay_status" class="tabsWrap" @change="handleTabs">
      <el-radio-button :label="1">待结算({{table.init_total}})</el-radio-button>
      <el-radio-button :label="2">已结算({{table.pay_total}})</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="客户名称" prop="merchant_id">
          <el-select v-model="table.params.merchant_id" filterable placeholder="请选择" clearable @focus="getMerchantSel" @change="changeMerchant">
            <el-option
              v-for="item in merchantArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单食堂" prop="address_id">
          <el-select v-model="table.params.address_id" filterable placeholder="请输入" clearable @focus="getStoreSel" @change="onSearch">
            <el-option v-for="item in storeArr" :key="item.id" :label="item.name" :value="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">({{ item.merchant_name }})</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单账号" prop="phone">
          <el-input
            size="mini"
            v-model.trim="table.params.phone"
            placeholder="请输入下单账号"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="下单时间" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            class="selDaterangeWidth"
            :picker-options="pickerOptions" @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="完成时间" prop="finish">
          <el-date-picker
            v-model="table.params.finish"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            class="selDaterangeWidth"
            :picker-options="pickerOptions" @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="配送日期" prop="distr_time">
          <el-date-picker
            v-model="table.params.distr_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            class="selDaterangeWidth"
            :picker-options="pickerOptions" @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <!-- 超级管理员才有地区查询条件 -->
        <el-form-item label="地区" prop="area" v-if="roleType == 1">
          <el-cascader
            v-model="table.params.area"
            :options="areaArr"
            :props="{ checkStrictly: true }"
            clearable
            filterable
            style="width:100%;" @change="onSearch">
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" plain @click="handleBatch">批量操作</el-button>
        <el-button :size="subUnitSize" type="primary" plain @click="exportData('/admin/order/exportPayList')" icon="el-icon-download">全部导出</el-button>
        <el-button :size="subUnitSize" type="primary" plain @click="plexportData('/admin/order/exportPayList')">批量导出</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="table.params.pay_status == 1 ? field : field2"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:status="{row}">
        <span>{{toStr({1:'待确认',2:'待收货',3:'已完成',4:'已取消'},row.status)}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button v-if="table.params.pay_status == 1" type="text" @click="view(row)">结清</el-button>
        <el-button type="text" @click="showDetail(row.id)">查看订单</el-button>
      </template>
    </VTable>

    <!-- 查看详情 -->
    <detail ref="detail"  @refresh="getTable"></detail>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'
import Detail from '@/layout/OrderDetail.vue'
import { getStorage } from '@/storage'

export default {
  name: '',
  components: {
    Detail,
    VTable,
  },
  mixins: [mixinTable],
  computed: {
    ...mapGetters(['areaArr'])
  },
  data() {
    return {
      roleType: getStorage('role_type'),// 1:超级管理员，2:管理员, 3：员工
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      multipleSelection: [],
      field: [
        { name: "order_no", label: "订单编号", showTooltip: true, hidden: false },
        { name: "merchant_name", label: "客户名称", showTooltip: true, hidden: false },
        { name: "store", label: "下单食堂", hidden: false},
        { name: "amount", label: "订单金额/元",hidden: false},
        { name: "status", label: "订单状态", hidden: false},
        { name: "finish_time", label: "完成时间", hidden: false},
        { name: "distr_date", label: "配送日期", hidden: false},
        { name: "action", label: "操作",width: "160", fixed:"right", hidden: false }
      ],
      field2: [
        { name: "order_no", label: "订单编号", showTooltip: true, hidden: false },
        { name: "merchant_name", label: "客户名称", showTooltip: true, hidden: false },
        { name: "store", label: "下单食堂", hidden: false},
        { name: "create_time", label: "下单时间", hidden: false},
        { name: "amount", label: "订单金额/元",hidden: false},
        { name: "pay_time", label: "结算时间", hidden: false},
        { name: "distr_date", label: "配送日期", hidden: false},
        { name: "action", label: "操作",width: "160", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          date:'',
          create_start: '',
          create_end: '',
          phone: '',
          merchant_id: '',
          pay_status: 1,
          finish: '',
          finish_start: '',
          finish_end: '',
          distr_time: '', // 配送日期
          distr_start:'',
          dist_end: '',
          address_id: '', // 下单食堂
          area:[],
          province: '',
          city: '',
          page: 1,
          count: 10
        },
        data: [{
          id: 1,
          order_no: '1',
          merchant_name: '1',
          merchant_account: '1',
          amount: '1',
          status: '1',
          finish_time: '1',
        }],
        total: 0,
        init_total: 0,
        pay_total: 0,
      },
      num: 1,
      cAmount: 0,
      storeArr: [],
      merchantArr: [],
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      let _params = {...this.table.params}
      let _date = _params.date;
      if(_date &&  _date.length > 0) {
        _params.create_start = _date[0]
        _params.create_end = _date[1]
      } else {
        _params.create_start = '';
        _params.create_end = '';
      }
      let _finish = _params.finish;
      if(_finish &&  _finish.length > 0) {
        _params.finish_start = _finish[0]
        _params.finish_end = _finish[1]
      } else {
        _params.finish_start = '';
        _params.finish_end = '';
      }
      let _distr = _params.distr_time;
      if(_distr &&  _distr.length > 0) {
        _params.distr_start = _distr[0]
        _params.distr_end = _distr[1]
        delete _params.distr_time
      } else {
        _params.distr_start = '';
        _params.distr_end = '';
      }
      let _area = _params.area;
      if(_area.length == 1) {
        _params.province = _area[0]
      } else if(_area.length == 2) {
        _params.province = _area[0]
        _params.city = _area[1]
      } else {
        _params.province = '';
        _params.city = '';
      }
      this.table.loading = true;
      this.$http.post('/admin/order/payList', _params).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.init_total = res.data.init_total;
          this.table.pay_total = res.data.pay_total;
        }
      })
    },
    // 查看、审核
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 结清
    view(row) {
      let amount = row.amount;
      this.$confirm(`<div>订单结算: ${this.num} 笔</div><div>结算金额: ${amount} 元</div>`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.batchFun(row.id); 
      }).catch(() => {
      });
    },
    // 批量结清
    handleBatch() {
      if(this.multipleSelection.length > 0) {
        let ids = this.multipleSelection.map((item) => {
          return item.id
        }).join(",")
        let _amount = this.multipleSelection.map(v => {
          return v.amount = v.amount++
        })
        let str = {
          amount: _amount,
          id: ids
        }
        this.view(str)
      } else {
        this.$message.warning('请选择需要批量操作的数据！')
      }
    },
    // 结清方法
    batchFun(ids) {
      this.$http.post('/admin/order/pay',{id: ids}).then(res => {
        if(res.code == 1) {
          this.$message.success('操作成功！')
          this.getTable();
        }
      })
    },
  }
}
</script>
